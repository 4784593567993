"use client"; 

import React, { createContext, useContext, useState, useEffect } from "react";
import { UserContext } from "./UserContext";
import { useRouter } from "next/navigation";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const router = useRouter();

  const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

  const { userData, setUserData } = useContext(UserContext);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (userData?.quantity) {
      setTotalItems(userData.quantity);
    }
  }, [userData]);

  const addToCart = () => {
    setTotalItems((prevTotal) => prevTotal + 1);
  };

  const sendSingleItemToServer = async (item) => {
    const payload = {
      userData,
      item,
    };

    try {
      const response = await fetch(`${BACKEND_URL}/api/cart/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status==403) {
        router.push("/login");
      }

      if (!response.ok) {
        throw new Error(`Error handling data from server - {response.status}`);
      }
      if (response.status!=200) {
        addToCart();
      }

    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  const deleteFromCartTotal = (quantity) => {
    setTotalItems((prevTotal) => prevTotal - quantity);
  };

  return (
    <CartContext.Provider
      value={{ totalItems, addToCart, sendSingleItemToServer, deleteFromCartTotal, setTotalItems }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
