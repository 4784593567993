"use client";

import React, { useContext } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import styles from "./navbar.module.css";
import { useCart } from "@/contexts/CartContext";
import { UserContext } from "@/contexts/UserContext";
import BalanceDisplay from "./BalanceDisplay";
import { useRouter } from "next/navigation";
import Image from 'next/image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faBook, faShield, faCartShopping } from '@fortawesome/free-solid-svg-icons';

export default function Navbar() {
  const { totalItems = 0 } = useCart();
  const { userData, isLoading } = useContext(UserContext);
  const pathname = usePathname();
  const router = useRouter();

  const isActive = (path) => pathname.startsWith(path);

  const handleRedirect = () => {
    router.push("/login");
  };

  return (
    <div>
      <div className={styles["header-top"]}>
        <div className={styles["user-info"]}>
          <Link href="/" className={styles.navLogo}>
            <div className={styles["profile-image-container"]}>
                <Image
                  width={32}
                  height={32}
                  src="/img/sz.svg"
                  alt="User"
                  className={styles.logotype}
                  quality={75}
                />
            </div>

            <div className={styles["user-details"]}>
              <span className={styles["user-name"]}>SafeZone.shop</span>
            </div>
          </Link>
        </div>

        <div className={styles["navigation-side"]}>
          <div className={styles["main-menu-large"]}>
            <Link
              href="/reviews"
              className={isActive("/reviews") ? styles.active : ""}
              title="Отзывы"
            >
              <FontAwesomeIcon icon={faStar} style={{ width: '16px', height: '16px' }} />
              Отзывы
            </Link>
            <Link
              href="/support"
              className={isActive("/support") ? styles.active : ""}
              title="Поддержка"
            >
              <FontAwesomeIcon icon={faShield} style={{ width: '16px', height: '16px' }} />
              Поддержка
            </Link>
            <Link
              href="/article"
              className={isActive("/article") ? styles.active : ""}
              title="Справочник"
            >
              <FontAwesomeIcon icon={faBook} style={{ width: '16px', height: '16px' }} />
              Справочник
            </Link>
            <Link
              href="/cart"
              className={isActive("/cart") ? styles.active : ""}
              title="Корзина"
            >
              <FontAwesomeIcon icon={faCartShopping} style={{ width: '16px', height: '16px' }}/>
              Корзина
              {totalItems > 0 && (
                <span className={styles.cartCount}>{totalItems}</span>
              )}
            </Link>
          </div>
        </div>

        {isLoading ? (
          <div className={styles["loading-placeholder"]}>
            <div className={styles.loadingContainer}>
              
            </div>
          </div>
        ) : userData ? (
          <Link className={styles["profile-info-container"]} href="/profile">
            <div className={styles["navigation-balance"]}>
              <div className={styles.druble}>
                <BalanceDisplay balance={`${userData?.balance}₽` || "0₽"} />
                <div>
                  <i className="fa-solid fa-ruble-sign"/>
                </div>
              </div>
            </div>
            <div>
              <div className={styles["profile-image-container"]}>
                <Image
                  width={32}
                  height={32}
                  src={userData?.profile_photo || "/img/profile2.svg"}
                  alt="User"
                  className={styles.userImage}
                  quality={75}
                  placeholder="blur"
                  blurDataURL="/loading.svg"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </Link>
        ) : (
          <button
            className={styles.LoginButton}
            onClick={handleRedirect}
            type="button"
          >
            Вход
          </button>
        )}

        
      </div>
    </div>
  );
};
