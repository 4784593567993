"use client";

import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";



export const UserContext = createContext({
  userData: null,
  setUserData: () => {},
  fetchUserData: () => {},
  isLoading: true,
});


export const UserProvider = ({ children }) => {
  const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTelegramReady, setIsTelegramReady] = useState(false);

  let ongoingRequest = null;

  useEffect(() => {
    if (typeof window !== "undefined" && !document.querySelector('script[src="https://telegram.org/js/telegram-web-app.js"]')) {
      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-web-app.js";
      script.async = true;
      script.onload = () => {
        setIsTelegramReady(true);
      };
      script.onerror = () => {
        console.warn("Не удалось загрузить Telegram WebApp API.");
      };
      document.head.appendChild(script);
    } else {
      setIsTelegramReady(true);
    }
  }, []);


  const fetchUserData = useCallback(async () => {
    if (ongoingRequest) return await ongoingRequest; 

    setIsLoading(true);
    const controller = new AbortController();

    try {
      ongoingRequest = (async () => {
        if (isTelegramReady && window.Telegram?.WebApp.initData) {
          const tg = window.Telegram.WebApp;
          tg.ready();
          tg.expand();
          
          const response = await fetch(`${BACKEND_URL}/user_info/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(tg.initData),
            signal: controller.signal,
          });

          if (!response.ok) {
            throw new Error(`Ошибка при получении данных: ${response.status}`);
          }

          const data = await response.json();
          const jwt_token = data.jwt_token;
          
          if (jwt_token) {
            localStorage.setItem("accessToken", jwt_token);
          }

          setUserData(data);
          return data;
        } else {
          const token = localStorage.getItem("accessToken");

          if (!token) {
            console.warn("Пользователь не авторизован (нет accessToken)");
            setUserData(null);
          } else {
            const response = await fetch(`${BACKEND_URL}/user_info/`, {
              method: "POST",
              headers: { 
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
              },
              credentials: "include",
              signal: controller.signal,
            });

            if (!response.ok) {
              throw new Error("Ошибка авторизации через токен");
            }

            const data = await response.json();
            setUserData(data);
            return data;
          }
        }
      })();

      await ongoingRequest;
    } catch (err) {
      if (err.name !== "AbortError") {
        console.warn("Ошибка при запросе на сервер:", err);
      }
      setUserData(null);
    } finally {
      setIsLoading(false);
      ongoingRequest = null;
    }

    return () => controller.abort();
  }, [BACKEND_URL, isTelegramReady]);

  useEffect(() => {
    if (isTelegramReady) {
      fetchUserData();
    }
  }, [isTelegramReady, fetchUserData]);

  const contextValue = useMemo(
    () => ({
      userData,
      setUserData,
      fetchUserData,
      isLoading,
    }),
    [userData, fetchUserData, isLoading]
  );

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};
