"use client";

import React from "react";
import styles from "./footer.module.css";
import Link from "next/link";
import { useCart } from "@/contexts/CartContext";

export default function Footer() {
  const { totalItems } = useCart();

  return (
    <>
      <div className={styles.footer}>
        <Link href="/support/" className={styles["footer-icon"]}>
          <div>
            <img src="/img/info.svg" alt="Перейти в поддержку" width={25} height={25}/>
          </div>
          <span className={styles.base_profile}>Инфо</span>
        </Link>

        <Link href="/" className={styles["footer-icon"]}>
          <div>
            <img src="/img/sz.svg" alt="Перейти на главную" width={25} height={25}/>
          </div>
          <span className={styles.base_profile}>Главная</span>
        </Link>

        <Link href="/cart/" className={styles["footer-icon"]}>
          <div>
            <img src="/img/cart.svg" alt="Перейти в корзину" width={25} height={25}/>
            <span className={styles["cart-quantity"]}>{totalItems || 0}</span>
          </div>
          <span className={styles.base_profile}>Корзина </span>
        </Link>
      </div>
      <div className={styles["bottom-background"]}></div>
    </>
  );
}
