"use client";

import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import styles from "./navbar.module.css";

export default function BalanceDisplay({ balance = 0 }) {

  const getNumericBalance = (balance) => {
    if (typeof balance === 'number') {
      return balance;
    } else if (typeof balance === 'string') {
      return parseFloat(balance) || 0;
    } else if (typeof balance === 'object' && balance !== null) {
      return parseFloat(balance.value) || 0;
    }
    return 0;
  };

  const numericBalance = getNumericBalance(balance);

  const [prevBalance, setPrevBalance] = useState(numericBalance);

  useEffect(() => {
    if (numericBalance !== prevBalance) {
      setPrevBalance(numericBalance);
    }
  }, [numericBalance, prevBalance]);

  const springProps = useSpring({
    from: { balanceValue: prevBalance },
    balanceValue: numericBalance,
    config: { tension: 170, friction: 26 },
  });

  return (
    <animated.div className={styles.balanceAmount}>
      {springProps.balanceValue.to((val) => {
        if (typeof val === 'number' && Number.isFinite(val)) {
          return `${val.toFixed(2)}₽`;
        }
        return "0.00₽";
      })}
    </animated.div>
  );
};
