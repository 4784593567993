import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/miniapps/safezone/szFrontend/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/miniapps/safezone/szFrontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/miniapps/safezone/szFrontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/miniapps/safezone/szFrontend/src/components/footer/Footer.js");
;
import(/* webpackMode: "eager" */ "/var/www/miniapps/safezone/szFrontend/src/components/footer/footers.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/miniapps/safezone/szFrontend/src/components/navbar/Navbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/miniapps/safezone/szFrontend/src/components/YandexMetrika.js");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/var/www/miniapps/safezone/szFrontend/src/contexts/CartContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/var/www/miniapps/safezone/szFrontend/src/contexts/UserContext.js");
